import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
} from 'components/containers/Containers';

import {
  Colors,
} from 'common';

export const Card = props =>

  <Container
    flex={props.flex}
    padding={props.margin}
  >

    <Container
      {...props}
      cursor={(props.onClick && 'pointer') || null}
      margin={null}
    />

  </Container>
;

Card.propTypes = {
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.string,
  flex: PropTypes.string,
  flexDirection: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  overflow: PropTypes.string,
};

Card.defaultProps = {
  backgroundColor: Colors.Surface,
  borderRadius: '6px',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  flex: null,
  flexDirection: 'column',
  margin: null,
  onClick: null,
  overflow: 'hidden',
};
