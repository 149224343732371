import React from 'react';

import {
  Text,
  Card,
  ContainerVH,
} from 'components';

import {
  TextStyles,
  Routes,
} from 'common';

import {
  Storage,
} from 'core';

import {
  withCommon,
} from 'hocs';

import {
  Cognito,
} from 'api';

const LoginF = props => {

  const formData = {};
  const next = Storage.get('next');

  const onChange = e => {
    formData[e.target.id] = e.target.value;
  };

  const onLogin = async () => {

    const {
      username,
      password,
    } = formData;

    if (!username || !password) {

      window.alert('I mean, with what?');
      return;
    }

    let res = await Cognito.login(
      username,
      password
    );

    if (!res.ok) {

      console.error(res.result.message);
      window.alert('Try harder next time');
      return;
    }

    if (res.result.ChallengeName && res.result.Session) {

      res = await Cognito.challenge(
        username,
        password,
        res.result.Session
      );
    }

    if (!res.ok ||
      !res.result.AuthenticationResult ||
      !res.result.AuthenticationResult.AccessToken) {

      window.alert('Try harder next time');
      return;
    }

    Storage.set(
      'user',
      res.result.AuthenticationResult
    );

    if (next) {

      props.common.push(next);
      return;
    }

    props.common.push(Routes.Comics);
  };

  Storage.remove('next');
  Storage.remove('user');

  return (

    <ContainerVH
      flex={'1'}
    >

      <Card
        padding={'5vmin'}
      >

        <Text
          formFactorProps={TextStyles.Header}
          text={'Login'}
        />

        <input
          id={'username'}
          onChange={onChange}
        />

        <input
          id={'password'}
          onChange={onChange}
          type='password'
        />

        <button
          onClick={onLogin}
        >
          Login
        </button>

      </Card>

    </ContainerVH>
  );
};

export const Login = withCommon(LoginF);
