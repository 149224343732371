import React from 'react';

import PropTypes from 'prop-types';

import {
  Route,
  Switch,
  HashRouter,
  BrowserRouter,
  MemoryRouter,
} from 'react-router-dom';

import {
  NavigatorType,
} from 'common';

export const Navigator = props => {

  if (!props.routes) {
    return [];
  }

  const routes = [];
  let defaultRouteSet = false;
  let defaultRoute = {};
  let routeEntries = Object.entries(props.routes);

  for (const [key, val] of routeEntries) {

    if (!val || !val.component || !val.path || val.path.length <= 0) {
      continue;
    }

    if (!defaultRouteSet && val.default && val.path.indexOf(':') < 0) {

      defaultRoute = val;
      defaultRouteSet = true;
    }

    routes.push(
      <Route
        component={val.component}
        key={key}
        path={val.path}
      />
    );
  }

  if (defaultRouteSet) {

    routes.push(
      <Route
        component={defaultRoute.component}
        key={'navigator_default_route'}
      />
    );
  }

  const Router = (props.type === NavigatorType.Hash && HashRouter) || (props.type === NavigatorType.Memory && MemoryRouter) || BrowserRouter;

  return (

    <Router
      basename={props.base}
    >

      <Switch>
        {routes}
      </Switch>

    </Router>
  );
};

Navigator.propTypes = {
  base: PropTypes.string,
  routes: PropTypes.object,
  type: PropTypes.string,
};

Navigator.defaultProps = {
  base: '/',
  routes: {},
  type: NavigatorType.Memory,
};
