import {
  Environment,
  Request,
} from 'core';

export class ComicApi {

  static get = async () => {

    try {

      let {
        cache,
        request,
      } = Request.post(
        `${Environment.current.apiHost}/`,
        {useCache: true}
      );

      return {
        cache: cache,
        request: request,
      };
    } catch (e) {

      return [
        {
          ok: false,
          result: {message: 'Could not load comics'},
        },
      ];
    }
  };

  static getImages = async (
    name,
    chapter
  ) => {

    try {

      let {request} = Request.post(
        `${Environment.current.apiHost}/${name}/${chapter}`
      );

      return request
        .then(res => {

          if (res.result.Items) {
            res.result = res.result.Items[0] || {};
          }

          return res;
        });
    } catch (e) {

      return [
        {
          ok: false,
          result: {message: 'Could not load comics'},
        },
      ];
    }
  }
}
