import React from 'react';
import styled from 'styled-components';

import {
  withCommon,
} from 'hocs';

import {
  ComicApi,
} from 'api';

import {
  useParams,
} from 'react-router-dom';

import {
  Routes,
  Colors,
} from 'common';

import {
  Text,
} from 'components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.img`
  margin: ${props => props.margin || 0};
  scroll-snap-align: start;
`;

const ReaderF = props => {

  const {
    name,
    chapter,
  } = useParams();

  const prev = parseInt(chapter) - 1;
  const next = parseInt(chapter) + 1;

  const [images, setImages] = React.useState([]);
  const url = `/${name}/${chapter}`;

  const scroll = e => {

    let scrollDivider = 1;

    switch (e.code) {

    case 'KeyD':

      setImages([]);

      props.common.push(
        Routes.Reader,
        {
          name: name,
          chapter: next,
        }
      );

      return;

    case 'KeyA':

      if (prev < 1) {
        return;
      }

      setImages([]);

      props.common.push(
        Routes.Reader,
        {
          name: name,
          chapter: prev,
        }
      );

      return;

    case 'KeyN':
      scrollDivider = 4;
      break;

    case 'KeyM':
      scrollDivider = 1.5;
      break;

    case 'KeyJ':
      scrollDivider = -4;
      break;

    case 'KeyK':
      scrollDivider = -1.5;
      break;

    default: return;
    }

    window.scrollTo({
      top: (window.innerHeight / scrollDivider) + window.scrollY,
      behavior: 'smooth',
    });
  };

  React.useEffect(
    () => {

      let mounted = true;

      ComicApi
        .getImages(
          name,
          chapter
        )
        .then(res => {

          if (!mounted || !res.ok) {
            return;
          }

          if (!res.result.images || !res.result.images.length) {

            window.alert(`No images found for ${name} = ${chapter}`);
            return;
          }

          setImages(res.result.images);
        });

      window.addEventListener(
        'keydown',
        scroll
      );

      return () => {

        mounted = false;

        window.removeEventListener(
          'keydown',
          scroll
        );
      };
    },
    [
      chapter, name, url,
    ]
  );

  if (!name || !chapter)  {

    return (

      <Text
        color={Colors.Text.onBackground}
        text={'please specify a name and chapter'}
      />
    );
  }

  console.log(images);

  if (!images || !images.length) {

    return (

      <Text
        color={Colors.Text.onBackground}
        text={'Loading...'}
      />
    );
  }

  const _images = [];
  let image;

  for (let i = 0; i < images.length; i++) {

    image = images[i];

    _images.push(

      <Image
        key={`${name}-${chapter}-${i}`}
        margin={'0 0 -5px 0'}
        src={image}
      />
    );
  }

  return (

    <Container>
      {_images}
    </Container>
  );
};

export const Reader = withCommon(ReaderF);
