import React from 'react';

import {
  ComicApi,
} from 'api';

import {
  withCommon,
} from 'hocs';

import {
  Card,
  Container,
} from 'components';

import {
  Comic,
  Chapter,
} from 'scenes/Comics/components';

import {
  Routes,
} from 'common';

const ComicsF = props => {

  const [state, setState] = React.useState({
    comics: [],
    chapters: [],
  });

  const onChapterClick = chapter => {

    props.common.push(
      Routes.Reader,
      {
        name: chapter.comicName,
        chapter: chapter.number,
      }
    );
  };

  const onComicClick = comic => {

    const chapters = [];

    for (let i = 1; i <= comic.chapters; i++) {

      chapters.push(

        <Chapter
          chapter={{
            number: i,
            comicName: comic.name,
          }}
          id={`${comic.name}-${i}`}
          key={`${comic.name}-${i}`}
          onClick={onChapterClick}
        />
      );
    }

    setState(prevState => ({
      ...prevState,
      chapters: chapters,
    }));
  };

  const update = comics => {

    const _comics = [];

    for (const comic of comics) {

      _comics.push(

        <Comic
          comic={comic}
          id={comic.name}
          key={comic.name}
          onClick={onComicClick}
        />

      );
    }

    setState(prevState => ({
      ...prevState,
      comics: _comics,
    }));
  };

  React.useEffect(
    () => {

      let mounted = true;

      ComicApi.get()
        .then(({
          cache,
          request,
        }) => {

          if (cache) {
            update(cache.result.Items);
          }

          request.then(res => {

            if (!mounted || !res.ok) {
              return;
            }

            update(res.result.Items);
          });
        });

      return () => {
        mounted = false;
      };
    },
    []
  );

  return (

    <Container
      flex={'1'}
    >

      <Card
        flex={'1'}
        margin={'1vmin'}
        maxHeight={'98vh'}
        overflow={'auto'}
      >
        {state.comics}
      </Card>

      <Card
        alignContent={'flex-start'}
        flex={'3'}
        flexDirection={'row'}
        flexWrap={'wrap'}
        margin={'1vmin'}
        maxHeight={'98vh'}
        overflow={'auto'}
      >
        {state.chapters}
      </Card>

    </Container>
  );
};

export const Comics = withCommon(ComicsF);
