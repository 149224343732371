import {
  FormFactors,
} from 'common/constants';

export const TextStyles = {

  Header: {[FormFactors.Desktop]: {fontSize: '17.5px'}},
  Body: {[FormFactors.Desktop]: {fontSize: '15px'}},
  Small: {[FormFactors.Desktop]: {fontSize: '11px'}},
  Action: {[FormFactors.Desktop]: {
    fontSize: '12px',
    textTransform: 'uppercase',
  }},
};
