export class Storage {

  static get = key => {

    let value = window.sessionStorage.getItem(key);
    return (value && value !== 'undefined' && JSON.parse(value)) || null;
  };

  static set = (
    key,
    val
  ) => {

    const current = Storage.get(key);
    let newVal = val;

    if (typeof val === 'object' && typeof val !== typeof current) {

      Array.isArray(newVal)
        ? newVal = [...current, ...val]
        : newVal = {
          ...current,
          ...val,
        };
    }

    window.sessionStorage.setItem(
      key,
      JSON.stringify(newVal)
    );
  };

  static remove = key => {
    window.sessionStorage.removeItem(key);
  };
}
