import {
  Chapters,
  Comics,
  Login,
  Reader,
} from 'scenes';

export const Routes = {
  Reader: {
    component: Reader,
    path: '/comic/:name/:chapter',
  },
  Chapters: {
    component: Chapters,
    path: '/comic/:name',
  },
  Comics: {
    component: Comics,
    path: '/comic',
  },
  Login: {
    component: Login,
    path: '/',
    default: true,
  },
};
