const AwsTypes = {
  S: 'string',
  N: 'number',
  BOOL: 'bool',
  M: 'object',
  L: 'list',
};

export const awsTypesToJson = items => {

  let _items = {};
  let itemValue;

  for (const [key, val] of Object.entries(items)) {

    itemValue = val;
    let entries = Object.entries(val);

    if (typeof val === 'object' && entries.length === 1 && !Array.isArray(val)) {

      let [[type, value]] = entries;
      itemValue = AwsTypes[type] ? value : val;
    }

    if (typeof itemValue === 'object') {

      Array.isArray(itemValue)
        ? itemValue = Array.from(Object.values(awsTypesToJson(itemValue)))
        : itemValue = awsTypesToJson(itemValue);

      ;
    }

    _items[key] = itemValue;
  }

  return _items;
};

export function decodeB64Unicode(base64) {

  const base64Uri = atob(base64)
    .replace(
      /(.)/g,
      (m, p) => {

        let code = p.charCodeAt(0)
          .toString(16)
          .toUpperCase();

        if (code.length < 2) {
          code = '0' + code;
        }

        return '%' + code;
      }
    );

  return decodeURIComponent(base64Uri);
}

export function getJWTPayload (token) {

  const base64Url = token.split('.')[1];

  const base64 = base64Url
    .replace(
      /-/g,
      '+'
    )
    .replace(
      /_/g,
      '/'
    );

  const jsonPayload = decodeB64Unicode(base64);

  return JSON.parse(jsonPayload);
}
