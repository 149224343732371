import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
} from 'components/containers';

import {
  Text,
} from 'components/general/Text';

import {
  Colors,
  TextStyles,
} from 'common';

export const Chapter = props => {

  const chapter = {
    ...props.chapter,
    id: props.id,
  };

  const onClick = () => {
    props.onClick && props.onClick(chapter);
  };

  return (

    <Card
      alignItems={'center'}
      height={'5vmin'}
      hoverBackgroundColor={Colors.LightAccent}
      justifyContent={'center'}
      margin={'1vmin'}
      maxHeight={'40px'}
      maxWidth={'40px'}
      minHeight={'35px'}
      minWidth={'35px'}
      onClick={onClick}
      padding={'1vmin'}
      width={'5vmin'}
    >

      <Text
        formFactorProps={TextStyles.Body}
        text={chapter.number}
      />

    </Card>

  );
};

Chapter.propTypes = {
  chapter: PropTypes.shape({
    comicName: PropTypes.string,
    number: PropTypes.string,
  }),
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Chapter.defaultProps = {
  chapter: {},
  id: null,
  onClick: () => {},
};
