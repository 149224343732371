/* eslint-disable react/prop-types */
import
React, {
  useContext,
} from 'react';

import {
  FormFactorContext,
} from 'contexts';

export const withFormFactor = (Component, options) => {

  options = options || {};
  options.formFactorProps = options.formFactorProps || {};

  return props => {

    const formFactor = useContext(FormFactorContext);
    const formFactorProps = props.formFactorProps ? props.formFactorProps[formFactor] : {};

    return (

      <Component
        {...options.formFactorProps[formFactor]}
        {...formFactorProps}
        {...props}
      />
    );
  };
};
