import React from 'react';

import {
  withCommon,
} from 'hocs';

const ChaptersF = props => {

  return (
    <div>{JSON.stringify(props)}</div>
  );
};

export const Chapters = withCommon(ChaptersF);
