import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.alignContent && `align-content: ${props.alignContent};`}
  ${props => props.background && `background: ${props.background};`}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props => props.backgroundImage && `background-image: ${props.backgroundImage};`}
  ${props => props.border && `border: ${props.border};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  ${props => props.boxSizing && `box-sizing: ${props.boxSizing};`}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${props => props.minHeight && `min-height: ${props.minHeight};`}
  ${props => props.minWidth && `min-width: ${props.minWidth};`}
  ${props => props.overflow && `overflow: ${props.overflow};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.transition && `transition: ${props.transition};`}
  ${props => props.width && `width: ${props.width};`}

  :hover {
    ${props => props.hoverAlignItems && `align-items: ${props.hoverAlignItems};`}
    ${props => props.hoverBackground && `background: ${props.hoverBackground};`}
    ${props => props.hoverBackgroundColor && `background-color: ${props.hoverBackgroundColor};`}
    ${props => props.hoverBackgroundImage && `background-image: ${props.hoverBackgroundImage};`}
    ${props => props.hoverBorder && `border: ${props.hoverBorder};`}
    ${props => props.hoverBorderRadius && `border-radius: ${props.hoverBorderRadius};`}
    ${props => props.hoverBoxShadow && `box-shadow: ${props.hoverBoxShadow};`}
    ${props => props.hoverColor && `color: ${props.hoverColor};`}
    ${props => props.hoverCursor && `cursor: ${props.hoverCursor};`}
    ${props => props.hoverFlex && `flex: ${props.hoverFlex};`}
    ${props => props.hoverFlexDirection && `flex-direction: ${props.hoverFlexDirection};`}
    ${props => props.hoverHeight && `height: ${props.hoverHeight};`}
    ${props => props.hoverJustifyContent && `justify-content: ${props.hoverJustifyContent};`}
    ${props => props.hoverMargin && `margin: ${props.hoverMargin};`}
    ${props => props.hoverOverflow && `overflow: ${props.hoverOverflow};`}
    ${props => props.hoverPadding && `padding: ${props.hoverPadding};`}
    ${props => props.hoverWidth && `width: ${props.hoverWidth};`}
  }
`;

export const ContainerV = styled(Container)`
  justify-content: center;
`;

export const ContainerH = styled(Container)`
  align-items: center;
`;

export const ContainerVH = styled(Container)`
  align-items: center;
  justify-content: center;
`;

export const HContainer = styled(Container)`
  flex-direction: column;
`;

export const HContainerV = styled(HContainer)`
  align-items: center;
`;

export const HContainerH = styled(HContainer)`
  justify-content: center;
`;

export const HContainerVH = styled(HContainer)`
  align-items: center;
  justify-content: center;
`;
