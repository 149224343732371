import React from 'react';

import PropTypes from 'prop-types';

import {
  FormFactors,
} from 'common';

export const FormFactorContext = React.createContext('Desktop');

export const FormFactorProvider = props => {

  const [formFactor, setFormFactor] = React.useState(props.formFactor);

  const detectFormFactor = () => {

    let newFormFactor;

    for (const [formFactor, width] of Object.entries(props.breakPoints)) {

      if (window.innerWidth > width) {
        newFormFactor = formFactor;
      }
    }

    setFormFactor(newFormFactor);
  };

  const setup = () => {

    if (props.formFactor) {
      return;
    }

    const mediaQueries = [];
    let mediaQuery;

    for (const width of Object.values(props.breakPoints)) {

      if (width === 0) {
        continue;
      }

      mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
      mediaQuery.addListener(detectFormFactor);
      mediaQueries.push(mediaQuery);
    }

    detectFormFactor();

    return () => {

      for (const mediaQuery of mediaQueries) {
        mediaQuery.removeListener(detectFormFactor);
      }
    };
  };

  React.useEffect(
    setup,
    [
      props.breakPoints,
      props.formFactor,
    ]
  );

  return (

    <FormFactorContext.Provider
      value={props.formFactor || formFactor}
    >
      {props.children}
    </FormFactorContext.Provider>
  );
};

FormFactorProvider.propTypes = {
  breakPoints: PropTypes.objectOf(
    PropTypes.number
  ),
  formFactor: PropTypes.string,
};

FormFactorProvider.defaultProps = {
  breakPoints: {[FormFactors.Desktop]: 0},
  formFactor: null,
};
