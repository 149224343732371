export const Colors = {
  Background: '#1F2022',
  Surface: '#ECEFEF',
  Success: '#5AA565',
  Warning: '#D8952D',
  Error: '#F44336',
  Primary: '#7C8D95',
  Dark: '#1E2124',
  LightAccent: '#969791',
  DarkAccent: '#897463',
  Light: '#ECEFEF',
  Text: {
    onBackground: '#ECEFEF',
    onSurface: '#1E2124',
  },
};

export const FormFactors = {Desktop: 'desktop'};

export const Names = {LMS: 'the-legendary-moonlight-sculptor'};

export const NavigatorType = {
  Browser: 'browser',
  Hash: 'hash',
  Memory: 'memory',
};

export const Routes = {
  Login: '/',
  Comics: '/comic',
  Chapters: '/comic/:name',
  Reader: '/comic/:name/:chapter',
};
