import React from 'react';

import {
  useParams,
  useLocation,
} from 'react-router-dom';

import {
  Storage,
  Request,
} from 'core';

import {
  Routes,
} from 'common';

import {
  Cognito,
} from 'api';

export const withCommon = Component => {

  return props => {

    const params = useParams();
    const location = useLocation();
    const user = Storage.get('user') || {};

    const goToLogin = path => {

      Storage.set(
        'next',
        path
      );

      props.history.push(Routes.Login);
    };

    if ((!user.AccessToken || !user.IdToken) && location.pathname !== Routes.Login) {

      goToLogin(location.pathname);
      return null;
    }

    Request.defaultHeaders = {
      ...Request.defaultHeaders,
      Authorization: user.IdToken,
    };

    const push = (
      path,
      params = {}
    ) => {

      path = path.replace(
        /:([a-zA-Z0-9]+)/g,
        (_, param) => params[param]
      );

      if (!Storage.get('user')) {
        goToLogin(path);
      }

      props.history
        ? props.history.push(path)
        : window.location.href = path;
    };

    const common = {push: push};

    const checkAuthTimeout = () => {

      let userAuth = Storage.get('user') || {};

      if ((Date.now() + 60000) > userAuth.ExpiresAt) {

        Cognito
          .refresh(userAuth.RefreshToken)
          .then(res => {

            if (!res.ok ||
              !res.result.AuthenticationResult ||
              !res.result.AuthenticationResult.AccessToken) {

              window.alert('Could not refresh sign on');
              goToLogin();
              return;
            }

            console.log('REFRESHED TOKEN SUCCESSFULLY');

            Storage.set(
              'user',
              res.result.AuthenticationResult
            );
          })
          .catch(e => {

            console.error(e);
            window.alert('Could not refresh sign on');
            goToLogin();
          });
      }
    };

    const setup = () => {

      let interval = setInterval(
        checkAuthTimeout,
        60000
      );

      checkAuthTimeout();

      return () => {
        clearInterval(interval);
      };
    };

    React.useEffect(
      setup,
      []
    );

    return (

      <Component
        {...props}
        common={common}
        params={params}
      />
    );
  };
};
