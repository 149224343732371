import styled from 'styled-components';

import {
  Colors,
} from 'common';

export const Separator = styled.div`
  background-color: ${props => props.backgroundColor || Colors.Background.Grey5};
  height: 1px;
  min-height: 1px;
  width: ${props => props.width || '100%'};
`;
