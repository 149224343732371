import React from 'react';

import {
  NavigatorType,
  Colors,
} from 'common';

import {
  Navigator,
  Container,
} from 'components';

import {
  Routes,
} from 'routes';

import {
  FormFactorProvider,
} from 'contexts';

import {
  Environment,
} from 'core';

Environment.init({
  ReactDev: {
    host: 'localhost:3000',
    isLocal: true,
    browser: NavigatorType.Browser,
  },
  ServeDev: {
    host: 'localhost:5000',
    apiHost: 'api.tisasillyplace.com',
    browser: NavigatorType.Memory,
  },
  S3: {
    host: 'tisasillyplace.com',
    apiHost: 'api.tisasillyplace.com',
    browser: NavigatorType.Memory,
  },
});

export const App = () =>

  <Container
    backgroundColor={Colors.Background}
    flex={1}
    flexDirection={'column'}
  >

    <FormFactorProvider>

      <Navigator
        routes={Routes}
        type={Environment.current.browser}
      />

    </FormFactorProvider>

  </Container>
;
