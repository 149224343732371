import {
  Storage,
} from 'core';

export class Environment {

  static init = envs => {

    let current;

    for (const [name, env] of Object.entries(envs)) {

      env.name = name;
      let protocol = env.isLocal ? 'http' : 'https';
      env.apiHost = `${protocol}://${env.apiHost || env.host}`;

      if (env.host === window.location.host) {
        current = env;
      }
    }

    Storage.set(
      'Envs',
      {
        envs: envs,
        current: current,
      }
    );
  };

  static get current() {

    const envs = Storage.get('Envs');
    return (envs && envs.current) || {};
  };
}
