import React from 'react';
import PropTypes from 'prop-types';

import {
  ContainerH,
} from 'components/containers';

import {
  Text,
} from 'components/general/Text';

import {
  Colors,
  TextStyles,
} from 'common';

export const Comic = props => {

  const comic = {
    ...props.comic,
    id: props.id,
  };

  const onClick = () => {
    props.onClick && props.onClick(comic);
  };

  return (

    <ContainerH
      cursor={'pointer'}
      hoverBackgroundColor={Colors.LightAccent}
      onClick={onClick}
      padding={'2vmin'}
    >

      <Text
        formFactorProps={TextStyles.Body}
        text={`${comic.label} - ${comic.chapters}`}
      />

    </ContainerH>
  );
};

Comic.propTypes = {
  comic: PropTypes.shape({
    name: PropTypes.string,
    chapters: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Comic.defaultProps = {
  comic: {},
  id: null,
  onClick: () => {},
};
