import {
  awsTypesToJson,
} from 'common';

import {
  Storage,
} from 'core/storage';

import {
  h32,
} from 'xxhashjs';

export class Request {

  static defaultHeaders = {};

  static post = (
    url,
    {
      headers = {},
      useCache,
    } = {}
  ) => {

    let hash = h32(
      url,
      'tisasillyplace'
    );

    let request = new Promise((
      resolve,
      reject
    ) => {

      const req = new XMLHttpRequest();
      req.responseType = 'json';

      req.onload = e => {

        if (!e || !e.target) {

          reject({
            ok: false,
            result: {message: 'No response event found'},
          });

          return;
        }

        let response = {
          ok: e.target.status >= 200 && e.target.status < 300,
          result: awsTypesToJson(e.target.response),
        };

        if (useCache && response.ok) {

          Storage.set(
            hash.toString(),
            response
          );
        }

        resolve(response);
      };

      req.open(
        'POST',
        `${url}`
      );

      req.setRequestHeader(
        'Content-Type',
        'application/json'
      );

      req.setRequestHeader(
        'X-Amz-Date',
        new Date()
          .toISOString()
      );

      for (const [header, value] of Object.entries(Request.defaultHeaders)) {

        req.setRequestHeader(
          header,
          value
        );
      }

      for (const [header, value] of Object.entries(headers)) {

        req.setRequestHeader(
          header,
          value
        );
      }

      req.send();
    });

    let cache = Storage.get(hash.toString());

    return {
      cache: cache,
      request: request,
    };
  };
}
