export class Cognito {

  static _url = 'https://cognito-idp.eu-central-1.amazonaws.com/';

  static _clientId = '7i14tpg1p9l0gegn4ltbvomhsn';

  static _getOptions = (
    action,
    body
  ) => {

    return {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': `AWSCognitoIdentityProviderService.${action}`,
      },
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify({
        ClientId: '7i14tpg1p9l0gegn4ltbvomhsn',
        ...body,
      }),
    };
  };

  static login = async (
    username,
    password
  ) => {

    const options = Cognito._getOptions(
      'InitiateAuth',
      {
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
        AuthFlow: 'USER_PASSWORD_AUTH',
      }
    );

    try {

      let res = await fetch(
        Cognito._url,
        options
      );

      let ok = res.status >= 200 && res.status < 300;
      let json = await res.json() || {};

      if (json.AuthenticationResult.ExpiresIn && ok) {
        json.AuthenticationResult.ExpiresAt = Date.now() + (json.AuthenticationResult.ExpiresIn * 1000);
      }

      return {
        ok: ok,
        result: json,
      };
    } catch (e) {

      return {
        ok: false,
        result: {message: e.message},
      };
    }
  };

  static challenge = async (
    username,
    password,
    session
  ) => {

    const options = Cognito._getOptions(
      'RespondToAuthChallenge',
      {
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ChallengeResponses: {
          NEW_PASSWORD: password,
          USERNAME: username,
        },
        Session: session,
      }
    );

    try {

      let res = await fetch(
        Cognito._url,
        options
      );

      return {
        ok: res.status >= 200 && res.status < 300,
        result: await res.json() || {},
      };
    } catch (e) {

      return {
        ok: false,
        error: e,
        result: {},
      };
    }
  };

  static refresh = async refreshToken => {

    const options = Cognito._getOptions(
      'InitiateAuth',
      {
        AuthParameters: {REFRESH_TOKEN: refreshToken},
        AuthFlow: 'REFRESH_TOKEN_AUTH',
      }
    );

    try {

      let res = await fetch(
        Cognito._url,
        options
      );

      let ok = res.status >= 200 && res.status < 300;
      let json = await res.json() || {};

      if (json.AuthenticationResult.ExpiresIn && ok) {
        json.AuthenticationResult.ExpiresAt = Date.now() + (json.AuthenticationResult.ExpiresIn * 1000);
      }

      return {
        ok: ok,
        result: json,
      };
    } catch (e) {

      return {
        ok: false,
        result: {message: e.message},
      };
    }
  };
}
